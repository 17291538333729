import { useEffect, useState } from "react";
import config from "../config";
import Gift from "../entities/Gift";
import fetcher from "../utils/fetcher";
import GiftView from "./GiftView";

export default function GiftsList({eventId, data}:any){
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [selected, setSelected] = useState<number>(data.holidayGiftID);

  useEffect(() => {
    const url = `${config.apiUrl}/HolidayGift/GetHolidayGiftList/${eventId}`;
    fetcher
      .get(url)
      .then((response) => response.json())
      .then((gifts) => setGifts(gifts.filter((g: Gift) => !g.isBackOfficeOnly)));
  }, [eventId])

    return (
    <ol>
          {gifts.map((gift) => (
            <li className="GiftItem">
              <GiftView gift={gift} selected={selected} onChange={setSelected}/>
            </li>
          ))}
        </ol>);
}