import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { useAuth } from "../../context/Auth";
import LoginButton from "./LoginButton";
import Instructions from "../Instructions";

export default function Login({children} : any) {
  let location = useLocation();


  const { isAuth } = useAuth();

  const { from } = (location.state as any) || { from: { pathname: "/" } };


  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Redirect to={from} />;

  return (<Instructions>
      <Box textAlign="center" position="static">
        <LoginButton  />
      </Box>
      </Instructions>
  );
}
