import config from "../config";

export default function GiftView({ gift, selected, onChange }:any) {
  return (
    <>
      <label className="giftext">
        <input
          className="radio"
          type="radio"
          name="holidayGiftID"
          value={gift.holidayGiftID}
          checked={+selected === +gift.holidayGiftID}
          onChange={(e) => onChange(e.target.value)}
        />
        <span className="uppercase bold">{gift.holidayGiftName}</span>
        <span className="hebrew bold">{gift.holidayGiftNameHeb}</span>
        <br />
        <div className="shops-text-container">
          <span className="shops-text strong">{gift.holidayGiftDescription}</span>
          <span className="hebrew shops-text strong">{gift.holidayGiftDescriptionHeb}</span>
        </div>
        
        {/*eslint-disable-next-line */}
        <img className="gift" src={`${config.apiUrl}/Image/GetImage/${gift.imageID}`} onClick={e=>e.preventDefault()} />
        <div className="shops-text strong"
        dangerouslySetInnerHTML={{
          __html: gift.htmlDescription,
        }}
      ></div>
      </label>
    </>
  );
}
