export default function Success({ setSubmitted }: any) {
  return (
    <div className="top15">
      <p className="inst-text mb-0">
        <span className="title">
          Thank you! <br />
          <br />
        </span>
        Your choice has been submitted successfully.
        <span className="text-success">
          <br />
          <br />
          <br />
          <br />
          To change your selection please  
          {/* eslint-disable-next-line */}
          <a href="#"
            className="goldc"
            onClick={(e) => {
              e.preventDefault();
              setSubmitted(false);
            }}
          >  click here
          </a>
        </span> <br />
          <br />
          <br />
          <br />
</p>
    </div>
  );
}
