import { useEffect, useState } from "react";
import config from "../config";
import fetcher from "../utils/fetcher";
import GiftsList from "./GiftsList";
import PersonalDetails from "./PersonalDetails";
import Success from "./Success";



export default function GiftForm({ employee, event }: any) {
  const [data, setData] = useState<any>();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (employee) {
      const url = `${config.apiUrl}/HolidayGift/GetEmployeeHolidayGift?eventID=${event.eventID}&employeeID=${employee.employeeID}`;
      fetcher
        .get(url)
        .then((response) => response.json())
        .then((gift) => setData({ ...gift, employee }));
    }
  }, [event.eventID, employee]);

  useEffect(() => {
    setSubmitted(!!(data && data.holidayGiftID));
  }, [data]);

  const submitGift = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    const d = { ...data, ...formProps };

    const url = `${config.apiUrl}/HolidayGift/UpdateEmployeeHolidayGift`;
    fetcher
      .post(url, {
        body: JSON.stringify(d),
      })
      .then(() => setData(d));
  };

  if (submitted)
    return <Success setSubmitted={setSubmitted} />;
  return data ? (
    <form onSubmit={submitGift}>
      <PersonalDetails data={data} isOfficeSelect={!event.shlCycleID} />
      <h3>Please choose your gift</h3>
      <GiftsList eventId={event.eventID} data={data} />
      {/* <span className="giftext2">Link for submission will be available soon</span> */}
      <span className="giftext2">Have any comments?</span>
      <br />
      <textarea name="comment" rows={4} cols={30}>
        {data.comment}
      </textarea>
      <input type="submit" className="submit" value="Submit"></input>
    </form>
  ) : <></>
}
