import React from 'react'
import {Button, CircularProgress} from '@material-ui/core'
import {useAuth} from '../../context/Auth'
import MicrosoftIcon from '../../icons/MicrosoftIcon'

export default function LoginButton() {
  const {login, isLoggingIn} = useAuth()

  return (
    <Button
      color="primary"
      type="submit"
      variant="outlined"
      onClick={() => login()}
      startIcon={<MicrosoftIcon />}
      endIcon={
        isLoggingIn && (
          <CircularProgress
            size={24}
            color="primary"
          />
        )
      }
    >
      Continue with Microsoft
    </Button>
  )
}
