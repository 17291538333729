import React, { useEffect, useState } from "react";
import config from "../config";
import fetcher from "../utils/fetcher";

interface Props {
  defaultValue: number;
  officeID: number;
  onChange?: (id: number) => void;
  disabled?: boolean;
}

const OfficeSelect = ({
  defaultValue,
  onChange,
  disabled,
  officeID,
}: Props) => {
  const [offices, setOffices] = useState<[]>([]);
  const [selected, setSelected] = useState<number>(defaultValue);

  useEffect(() => {
    const url = `${config.apiUrl}/CodeTable/GetOfficeToCollectList`;
    fetcher
      .get(url)
      .then((response) => response.json())
      .then((data) => {
        const office = data.find((o: any) => +o.key === officeID);
        if (office) setOffices(data.filter((o: any) => +o.key !== 9));
        else setOffices(data);
      });
  }, [officeID]);

  return (
    <>
      <select
        required
        name="officeCollectID"
        value={selected}
        onChange={(e: any) => {
          const value = e.target.value;
          setSelected(value);
          onChange?.(value);
        }}
        disabled={disabled}
      ><option value="">Choose</option>
        {offices.map((office: any) => {
          return <option value={office.key}>{office.value}</option>;
        })}
      </select>
    </>
  );
};

export default OfficeSelect;
