const Instructions = ({ children }: any) => {
  return (
    <>
      <div>
        <p className="wlcm">Welcome!</p>
        <p className="inst-text mb-0">
          <span className="title">
            In order to choose a gift  <br />
            you must be registered in the system
             <br />על מנת לבחור מתנה עלייך להיות רשום
          {" "}
          
          <br />
          Let’s get started! </span> <br /><br /> Click to log in &nbsp; 
       
        <span className="inst-text hebrew mt-0">
          
       לחץ כדי להתחיל
       </span></p>
        {children}
      </div>

      <div className="top30 border-t">
        <p className="inst-text">
          Sign in using your primary email and your password &nbsp; &nbsp;
          <br />
          <span className="hebrew"> הרשמה באמצעות מייל וסיסמא </span>
        </p>
        {/* eslint-disable-next-line */}
        <img src="images/2.JPG" className="img-screen" />
      </div>

      <div className="top30 border-t">
        <p className="inst-text">
          Accept offer to stay signed in &nbsp; &nbsp;
          <br />
          <span className="hebrew"> לחצו על "כן" להשארת החיבור </span>
        </p>
        {/* eslint-disable-next-line */}
        <img src="images/3.JPG" className="img-screen" />
        <br />
        {/* eslint-disable-next-line */}
        <img src="images/4.png" className="img-screen" />
      </div>

      <div className="top15 border-t">
        <p className="inst-text mb-0">
          Not able to sign it ? Please try again…. If still not successful
          please email:{" "}
          <a href="mailto:itsupport@citybook.co.il" color="secondary">
            itsupport@citybook.co.il
          </a>
        </p>
        <p className="inst-text hebrew mt-0">
          {" "}
          שגיאה ברישום ? נסו שנית <br />
          עדיין לא הצלחתם ? שלחו מייל ל
          <a href="mailto:itsupport@citybook.co.il" color="secondary">
            itsupport@citybook.co.il
          </a>{" "}
        </p>
      </div>
      <div className="top15 border-t"></div>
    </>
  );
};

export default Instructions;
