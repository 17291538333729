import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/Auth";
import Loading from "./components/Loading";
import { ThemeProvider } from "@material-ui/core";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import theme from "./Theme";
import Home from "./components/Home";
import IEWarning from "./components/IEWarning";
import Wrapper from "./components/Wrapper";
const isIE =  /MSIE|Trident/.test(window.navigator.userAgent);

function App() {
  return ( isIE ? <IEWarning /> :
    <Wrapper>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Switch>
              <ProtectedRoute exact path="/">
                <Home />
              </ProtectedRoute>
              <Route path="/login">
                <Login/>
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </AuthContextProvider>
    </ThemeProvider>
    </Wrapper>
  );
}

export default App;
