export default function IEWarning() {
  return (
    <div className="top15">
      <p className="inst-text mb-0">
        <span className="title">
        This page is not supported by Internet Explorer. Please use Chrome or Edge. <br /><br/>
        If you are having issues please send a screen shot of your entire screen to: <a href="mailto:chagim@citybook.co.il" color="secondary">
            chagim@citybook.co.il
          </a>
        </span>
      </p>
    </div>
  );
}
